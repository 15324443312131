.shadow {
	box-shadow: 0 .5rem 1rem rgba(110, 113, 138, .15) ! important;
}

b , strong {
	font-weight: 700;
}

a {
	color: #212529;
}

#navbarTogglerTop {
	a {
		font-size: 1rem;
		&:hover , &.promo {
		}

		&.promo {
			font-weight: 600;
		}
	}
}

#parent-category{
 display:none;
}

.promo_wrapper {
	width: 100%;
	text-align: center;
	line-height: 1em;
	padding: 11px 0;
	i a {
		color: parent;
		text-decoration: underline;
	}
}

.ajaxLoader {
	background: url('../images/ajax-loader.gif');
	background-size: contain;
	background-repeat: no-repeat;
	display: none;
	margin: 0 auto;
	max-width: 100%;
	max-height: 100%;
	&.on {
		display: block;
	}
}

section.first {
	padding-top: 122px;
	min-height: 100vh;
	background: url('../images/top.jpg');
	background-size: cover;
	display: flex;
	align-items: center;
	background-attachment: fixed;
	.container {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}

.btn-theme.arrow-right {
	i {
		margin-left: 6px;
		margin-right: 2px;
	}

	&:hover {
		i {
			margin-left: 8px;
			margin-right: 0;
		}
	}
}

.btn-theme {
	font-size: 13px;
	line-height: 13px;
	padding-top: 18px;
	padding-bottom: 18px;
	text-align: center;
	font-weight: 700;
	&.btn-lg {
		border-radius: 35px;
	}
}

.btn-theme-2 {
	&.btn-lg {
		border-radius: 35px;
	}
}

.box-shadow {
	box-shadow: 0px 30px 42px -7px rgba(0, 0, 0, 0.68);
}

.breadcrumb {
	background-color: #f9f9f9;
	padding-top: 17px;
	padding-bottom: 17px;
	margin-bottom: 0;
	font-style: italic;
	border-bottom: 1px solid #e5e5e5;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	li {
		font-size: 12px;
		line-height: 16px;
		margin: 0;
	}

	a {
		color: #6c757d;
	}
}

.box-on-image {
	background-color: white;
	h2 {
		font-size: 18px;
		line-height: 1.2em;
		padding: 7px 26px;
		margin: 0 0 20px 0;
		display: inline-block;
		font-weight: 700;
	}

	h1 {
		font-size: 28px;
		line-height: 1.2em;
		margin: 0 0 30px 0;
		display: block;
		font-weight: 700;
	}

	h3 {
		font-size: 25px;
		line-height: 1.1em;
		margin: 0 0 20px 0;
		display: block;
		font-weight: 400;
	}

	.inner {
		padding: 50px 20px 50px 35px;
	}

	.btn {
		margin-top: 30px;
	}
}

li {
	font-size: 15px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.img-left-full {
	position: absolute;
	right: 0;
	height: 100%;
	overflow: hidden;
}

.inner-top {
	padding-top: 20px;
}

.inner-bottom {
	padding-bottom: 20px;
}

.standard-text {
	.inner {
		padding-top: 160px;
		padding-bottom: 20px;
	}

	ul {
		list-style: none;
		padding: 0;
		margin-left: 2em;
		li {
			position: relative;
		}

		li:before {
			content: "\2713";
			font-size: 20px;
			display: block;
			margin-left: -1em;
			width: 1em;
			margin-top: -3px;
			position: absolute;
		}
	}
}

ul.arrows {
	list-style: none;
	li:before {
		content: "➥";
		display: inline-block;
		margin-left: -1.3em;
		width: 1.3em;
		margin-top: 25px;
	}
}

.standard-page {
	.heading {
		font-weight: 800;
	}
}

.heading {
	font-size: 30px;
	line-height: 1.2em;
	font-weight: 700;
	width: 100%;
	margin: 0 0 20px 0;
	&::after {
		display: block;
		margin: 5px 0 0 0;
		height: 3px;
		content: " ";
		text-shadow: none;
		width: 30px;
	}
}

.text-center {
	.heading {
		&::after {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.image-wrapper {
	background-size: cover;
	background-repeat: no-repeat;
	.inner {
		padding: 75px 0;
		p {
			font-size: 15px;
		}
	}
}

.image-wrapper-color {
	width: 100%;
	opacity: 0.9;
	filter: alpha(opacity=90);
}

span.ul {
	font-size: 40px;
	margin-right: 20px;
	font-weight: 700;
	float: left;
	margin-top: -15px;
}

.clear {
	clear: both;
}

.left-and-right-grid {
	padding: 0;
}

.left-and-right-grid.first-block {
	padding-top: 65px;
	.heading_p {
		text-transform: uppercase;
		span {
			float: left;
			margin-top: -20px;
		}
	}
}

.left-and-right-grid.last-block {
	padding-bottom: 65px;
}

.heading_p {
	font-size: 15px;
	font-weight: 700;
}

.authors {
	margin-top: 20px;
	font-style: italic;
	strong {
		font-style: normal;
	}

	&:before {
		content: "✓";
		display: inline-block;
		width: 1.3em;
		margin-right: 15px;
	}
}

#program {
	padding: 10px 0;
	.head-box {
		padding: 20px;
		margin-bottom: 20px;
		.heading-program {
			font-weight: 700;
			font-size: 25px;
		}
	}

	.more {
		display: none;
		float: right;
	}

	.inner {
		clear: both;
	}
}

.btn-time {
	padding: 15px 20px;
	display: inline-block;
	font-size: 15px;
	line-height: 20px;
	max-width: 100%;
	overflow: hidden;
	.ul {
		font-size: 40px;
		position: relative;
		top: 50%;
		left: 0;
		margin: 0 25px 0 0;
	}
}

.btn-time.btn-time-2 {
	font-weight: 400;
}

.program-theme1 {
	.program-time-box {
		margin-top: -35px;
		margin-bottom: 30px;
	}

	.head-box {
		margin-top: 35px;
	}
}

.program-theme2 {
	.program-time-box {
		margin-top: -10px;
		.btn-time {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.program-head-box {
	}
}

.arrow {
	font-size: 15px;
	h3 {
		font-weight: 700;
		font-size: 15px;
		&:before {
			content: "→";
			display: inline-block;
			width: 30px;
			margin-right: 10px;
		}

		&:after {
			content: "←";
			display: inline-block;
			width: 30px;
			margin-left: 10px;
		}
	}

	&.left {
		h3 {
			&:before {
				display: none;
			}
		}
	}

	&.right {
		h3 {
			&:after {
				display: none;
			}
		}
	}
}

.left-and-right-grid2.last-block {
	padding-bottom: 40px;
}

section.image-col {
	.img-bg {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 200px;
	}

	&.image-left {
		.img-bg {
			right: 0;
		}
	}

	.col-md-6 {
		.img-bg {
		}
	}

	&.image-bg {
		.image-wrapper-color {
			height: 100%;
		}

		.promo {
			z-index: 100;
			display: flex;
			align-items: center;
			height: 100%;
			.btn-theme {
				background: transparent;
				padding-left: 30px;
				padding-right: 30px;
				margin-top: 12px;
			}
		}

		.heading {
			margin-bottom: 20px;
		}

		.promo {
			.heading::after {
				background-color: white;
			}
		}
	}
}

.img-bg100 {
	background-size: cover;
	background-repeat: no-repeat;
	height: 100%;
	z-index: -100;
	.image-wrapper-color {
		height: 100%;
	}
}

.wrapper-contact {
	.img-bg100 {
		min-height: 170px;
		.image-wrapper-color {
			min-height: 170px;
		}
	}
}



.prelegenci {
	&.AuthorsVertical , &.AuthorsVerticalWithImage {
		.prelegent-row {
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid #f3f4f5;
		}
	}

	h5 {
		color: black;
	}

	background-color: #ffffff;
	padding: 40px 0px 20px;
	.opis {
		margin: 10px 0;
		padding: 5px 0;
		p {
			margin-bottom: 0;
			margin-left: 0;
		}

		.text {
			overflow: hidden;
			p {
			}
		}
	}

	.wiecej , .mniej {
		text-align: center;
		cursor: pointer;
		font-weight: bold;
		padding: 0 5px;
		display: inline;
		margin: 0 auto;
	}

	.col-md-3 {
		img {
			width: 200px;
			border-radius: 300px;
		}
	}

	.col-md-4 {
		img {
			width: 200px;
			border-radius: 300px;
		}
	}

	.col-md-5 {
		img {
			width: 300px;
			border-radius: 400px;
		}
	}

	.align-items-center {
		img {
			margin: 0 auto;
			display: block;
		}
	}

	img {
		max-width: 100%;
	}

	.prelegent-row {
		margin-bottom: 40px;
	}

	.prelegent-box {
		padding: 20px;
		text-align: center;
		h5 {
			font-size: 24px;
			margin-bottom: 10px;
			font-weight: 700;
		}
	}
}

.box-prices {
	padding-bottom: 50px;
}

.box-shadow-2 {
	box-shadow: 0 0 20px 2px #D7D9DD;
}

.price-box {
	&.promo-price-box {
		.price-box-content .price-box-content {
			border: 0;
			padding: 20px 0;
		}
	}

	.price-box-content {
		border-left: 1px solid #D2D2D2;
		border-right: 1px solid #D2D2D2;
		border-bottom: 1px solid #D2D2D2;
		padding-left: 30px;
		padding-right: 30px;
		ul {
			margin-left: -30px;
			margin-right: -30px;
		}
	}
}

.price-box-header {
	font-size: 18px;
	font-weight: 700;
	line-height: 1em;
	padding: 21px 10px;
	position: relative;
	&.promo {
		padding-left: 20px;
		.heading {
			margin-bottom: 10px;
		}
	}

	h5 {
		font-size: 1.4em;
	}
}

.price-box-content {
	padding-top: 20px;
	font-size: 1rem;
	margin-bottom: 40px;
	h1 , h3 , h4 {
		font-weight: 700;
	}

	h1 {
		font-size: 40px;
	}

	h3 {
		font-size: 1rem;
	}

	h2 {
		font-size: 20px;
		text-decoration: line-through;
	}

	ul {
		padding: 20px 0 0 0;
		list-style-type: none;
		display: flex;
		flex-direction: column;
		height: 100%;
		margin-bottom: 3px;
	}

	li {
		list-style-type: none;
		padding: 20px 20px;
		min-height: 2em;
		align-items: center;
		margin-bottom: 0;
		justify-content: center;
		margin: 0;
		s {
			opacity: 0.3;
		}
	}
}

.price-box-desc {
	h1 , h2 , h3 , h4 {
		display: inline;
	}

	.heading {
		font-size: 25px;
	}
}

.wrapper-contact {
	padding: 30px 0;
}

.wrapper-contact-box {
	font-size: 25px;
	display: flex;
	padding: 10px 20px 10px 20px;
	align-items: center;
	min-height: 170px;
	flex-direction: row;
	position: absolute;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100%;
	max-width: 100%;
	flex-wrap: wrap;
	.btn {
		flex: 0 1 50%;
		align-self: center;
	}
}

footer {
	font-size: 14px;
	padding: 10px 0 20px;
	.box {
		padding-top: 30px;
	}

	h6 {
		font-size: 1rem;
		font-weight: 700;
		margin-bottom: 35px;
	}

	span {
		display: block;
		font-style: italic;
	}

	ul {
		list-style-type: none;
		margin-left: 0;
		padding-left: 0;
	}

	li {
		margin-bottom: 5px;
		font-size: 14px;
	}

	a {
	}

	a:hover {
		text-decoration: none;
		cursor: pointer;
	}
}

.fixed-active {
	position: fixed;
}

.dom-box-find {
	border: 1px solid red;
}

.modal-theme {
	.modal-title {
		display: block;
		text-align: center ! important;
		width: 100%;
		font-size: 26px;
	}

	.modal-body {
		max-width: 700px;
		margin: 0 auto;
		width: 100%;
	}

	.modal-header {
		border: none;
		.close {
		}
	}
}

.form-group-checkbox {
	label {
		display: inline;
	}

	input[type=checkbox] {
		float: left;
		margin-right: 10px;
	}
}

.form-control-big {
	.form-group {
		margin-bottom: 1.5rem;
	}

	textarea {
		min-height: 300px;
	}

	.form-control {
		box-shadow: 0px 3px 26px -12px rgba(0, 0, 0, 0.68);
		border: 1px solid #f8f8f8;
	}
}

.black-standard {
	color: black;
	text-decoration: underline;
}

.form-info {
	color: #aaaaaa;
}

.ajax-status {
	display: none;
}

.form-standard {
	label.error {
		color: #eb053b;
		margin-left: 10px;
		margin-top: 4px;
	}

	input.error , textarea.error {
		background: rgba(235, 5, 59, 0.1);
	}
}

#copy {
	padding-top: 20px;
	color: #aaaaaa;
}

.toggle-content {
	display: none;
}

.toggle-content-btn {
	&.toggle-close {
		span:after {
			font-family: 'FontAwesome';
			content: "\f077";
			margin-left: 5px;
			width: 1em;
			font-size: 0.8em;
		}

		.toggle-close {
			display: none;
		}

		.toggle-open {
			display: block;
		}
	}

	&.toggle-open {
		span:after {
			font-family: 'FontAwesome';
			content: "\f078";
			margin-left: 5px;
			width: 1em;
			font-size: 0.8em;
		}

		.toggle-close {
			display: block;
		}

		.toggle-open {
			display: none;
		}
	}
}

.toggle-content.toggle-close {
	display: none;
}

.toggle-content.toggle-open {
	display: block;
	transition: all 0.25s ease-in-out;
}

.term-box {
	max-width: 30%;
	float: left;
	padding: 30px 30px 30px 50px;
	border-radius: .45rem;
	margin-right: 10px;
	margin-top: 30px;
	cursor: pointer;
	p {
		margin-bottom: 0;
	}

	a {
		color: inherit;
		text-decoration: none;
	}

	h3 {
		text-transform: uppercase;
		font-size: 18px;
		font-weight: 700;
		margin-left: 0px;
		&:before {
			font-family: 'FontAwesome';
			content: "\f041";
			position: absolute;
			margin-left: -30px;
			width: 1em;
			font-size: 1.2em;
		}
	}
}

.row-center {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: center;
	align-content: center;
	align-items: center;
	.item {
		order: 0;
		flex: 0 1 100%;
		align-self: auto;
	}
}

.section-separator {
	position: relative;
}

.standard-text ul.li-ico , ul.li-ico {
	margin-left: 70px;
	strong {
		font-size: 18px;
	}

	li:before {
		content: none;
		margin: 0;
	}

	p {
		margin-top: 10px;
		font-size: 15px;
		color: #888888;
	}

	.ico {
		display: flex;
		min-height: 84px;
		div {
			align-self: center;
			flex: 0 1 auto;
			padding-left: 25px;
		}
	}

	.ico:before {
		width: 84px;
		margin-left: -100px;
		height: 84px;
	}
}

.mosaic {
	align-content: center;
	h4 {
		font-size: 16px;
		line-height: 25px;
		font-weight: 600;
		text-align: center;
		position: relative;
		top: 50%;
		transform: translateY(-50%);
		font-style: italic;
		&:hover {
			opacity: 0.7;
		}
	}

	img {
		height: 100%;
		width: 100%;
		overflow: hidden;
		&:hover {
			opacity: 0.7;
		}
	}
}

.slider-box {
	padding: 10px;
	.slider-box-header {
		height: 66px;
	}

	h3 {
		font-size: 24px;
		padding: 0;
		margin-bottom: 10px;
		margin-top: 15px;
		max-width: 100%;
		font-weight: 700;
	}

	.heading {
		font-size: 12px;
		margin: 0 0 10px;
	}

	p {
		color: #888888;
		font-size: 15px;
		margin-top: 10px;
		margin-bottom: 35px;
	}

	.more-conference {
		text-decoration: none;
		font-size: 15px;
		font-weight: 700;
	}

	hr {
		margin: 0 auto;
		max-width: 26px;
		height: 2px;
	}

	.shadow {
	}

	.desc {
		padding: 10px 10px 40px;
		margin-bottom: 20px;
		&.rounded {
			border-top-left-radius: 0 ! important;
			border-top-right-radius: 0 ! important;
		}
	}
}

.navbar {
	.ico-cal {
	}
}

.ico-box {
	.ico-cal , .ico-pin {
		display: inline-block;
		position: relative;
		&:before {
			left: -30px;
		}
	}
}

.ico-cal , .ico-pin , .ico-price {
	text-decoration: none;
	font-size: 17px;
	font-weight: 700;
	padding-left: 30px;
	display: inline-block;
	margin-bottom: 5px;
	position: relative;
	&:before {
		position: absolute;
		left: 0px;
	}

	&:hover {
		text-decoration: none;
	}

	span {
		color: #888888;
		font-size: 15px;
	}
}

.ico-cal {
	padding-left: 35px;
}
.navbar .ico-cal {
	padding-left: 5px;
}

#nadchodzace-szkolenia {
	margin-bottom: 30px;
}



.conference-item {
	border-top-left-radius: .45rem ! important;
	border-bottom-left-radius: .45rem ! important;
	border-top-right-radius: .45rem ! important;
	border-bottom-right-radius: .45rem ! important;
	&.disabled {
		background: #F5F5F5;
		color: #787878;
		.title {
			color: #787878;
		}

		.type {
			background: #787878 ! important;
		}
	}

	display: flex;
	flex-direction: row;
	min-height: 200px;
	width: 100%;
	.category-tab {
		align-items: center;
		width: 55px;
		border-top-left-radius: .45rem ! important;
		border-bottom-left-radius: .45rem ! important;
		font-size: 18px;
		line-height: 1em;
		color: white;
		align-content: center;
		justify-content: center;
		display: flex;
		text-align: center;
		div.color {
			border-top-left-radius: .45rem ! important;
			border-bottom-left-radius: .45rem ! important;
			flex: 0 1 auto;
			align-self: center;
			height: 100%;
			padding-top: 10px;
			padding-bottom: 10px;
			align-content: center;
			justify-content: center;
			display: flex;
			width: 55px;
			div {
				flex: 0 1 auto;
				align-self: center;
			}
		}

		span {
			text-transform: capitalize;
			white-space: wrap;
			display: block;
			transform: rotate(-90deg);
			font-weight: 600;
		}
	}

	.category-right-tab {
		width: calc(100% - 55px);
		position: relative;
		flex: 1;
		border-top-right-radius: .45rem ! important;
		border-bottom-right-radius: .45rem ! important;
		padding: 18px 24px 24px 40px;
		> div.row {
			div.col-md-6:first-child {
				border-right: 1px solid #F5F5F5;
			}
		}
	}

	font-size: 15px;
	margin-bottom: 20px;
	.type {
		font-size: 12px;
		line-height: 21px;
		padding: 0 10px;
		display: inline-block;
		color: white;
		border-radius: 21px;
		margin-bottom: 7px;
		font-weight: 700;
		&.stationary {
			background: #FF3400;
		}

		&.online {
			background: #31AE72;
		}
	}

	.title {
		font-size: 20px;
		font-weight: 700;
	}

	.btn-theme {
		background: none;
		padding-top: 13px;
		padding-bottom: 13px;
	}

	.btn-box {
		justify-content: flex-end;
		display: flex;
		align-self: flex-end;
		position: absolute;
		bottom: 24px;
		right: 15px;
	}

	.btn-box-margin {
		clear: both;
		margin-bottom: 50px;
	}

	.ico-cal , .ico-pin , .ico-price {
		position: relative;
		font-size: 15px;
		font-weight: 600;
		&:before {
			width: 15px;
			height: 15px;
		}

		padding-left: 20px;
		margin-bottom: 23px;
		margin-left: 0px;
	}

	.ico-pin:before {
	}

	.ml-ico {
	}
}

.ico-pin {
	&:before {
	}
}


.wrapper-contact-bg{
  background-image: url('/layout/images/wrapper-contact-box.jpg');
}

.standard-page{


	.image-wrapper-color {
		background-color: transparent;
display:none;
	}

	.wrapper-contact-bg{
	  background-image: url('/layout/images/wrapper-contact-box-color.jpg');
	}
}

.round-section-separator {
	background: white;
	.item {
		font-size: 15px;
		h3 {
			display: block;
			font-size: 40px;
			font-weight: 700;
		}
	}
}



#category-header-box {
	background-repeat: no-repeat;
	background-size: cover;
	#category-header {
		min-height: 305px;
		color: white;
		h1 {
			font-size: 30px;
			margin: 0 0 20px;
			font-weight: 800;
		}

		p {
			font-size: 15px;
		}
	}
}

span.checkbox-ico {
	display: inline-block;
	border: 1px solid #787878;
	width: 13px;
	height: 13px;
	border-radius: 13px;
	margin-right: 10px;
	position: relative;
}

span.checkbox-ico.check::after {
	@extend  %span-checkbox-ico-check;
}

.navbar-search.navbar-site {
	display: inline-block;
	padding: .5rem 1rem 1rem 0;
	.dropdown-toggle , .dropdown-menu {
		width: 230px;
	}

	.nav-item.dropdown {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	.nav-link {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.dropdown-menu {
		padding-left: 10px;
		margin-top: -10px;
		box-shadow: 0 1.5rem 1rem rgba(0, 0, 0, .15) ! important;
	}

	.dropdown-toggle {
		padding-top: 3px;
		padding-bottom: 3px;
	}

	.dropdown-toggle::after {
		position: absolute;
		right: 10px;
		top: 10px;
	}

	.rounded {
		border-radius: 1rem ! important;
	}

	li {
		font-size: 13px;
		a {
			font-size: 13px;
		}
	}
}


#cookie-policy {
	background-color: rgba(255, 255, 255, 0.7);
	bottom: -1px;
	left: 0;
	opacity: 1;
	position: fixed;
	right: 0;
	width: 100%;
	z-index: 1000;
	.cookie_div {
		padding: 5px 0;
	}

	a {
		font-weight: 700;
		color: blue;
		text-decoration: none;

	}

	#cookie-policy-info {
		img {
			float: left;
			margin-right: 10px;
			margin-bottom: 5px;
		}
	}

	#cookie-policy-accept {
		cursor: pointer;
		padding: 5px 10px;
		display: inline-block;
		width: 80%;
		margin: 5px auto 0;
		text-align: center;
		&:hover {
			text-decoration: none;
		}
	}
}

@media (min-width: 767px) {
	#cookie-policy {
		.cookie_div {
			padding: 20px 0;
		}
	}
}


#cookie-policy {
	background-color: rgba(255, 255, 255, 0.9);
	border-top: 2px solid black;
	
	#cookie-policy-accept {
		color: #a6204c;
		border: 1px solid #a6204c;
		&:hover {
			color: white !important;
			background-color: #a6204c;
		}
	}
}




@media screen and (max-width: 767px) {
	.standard-text ul.li-ico , ul.li-ico {
		margin-left: 120px;
	}

	.ico-box {
		order: -2;
		width: 100%;
		text-align: center;
		padding-bottom: 10px;
	}

	.shadow.no-mobile-shadow {
		border: none;
		border-radius: 0;
		box-shadow: unset ! important;
	}

	.term-box {
		max-width: 100%;
	}

	.wrapper-contact-box {
		p {
			text-align: center;
			width: 100%;
		}
	}

	.left-and-right-grid2 {
		.arrow {
			h3 {
				&:after {
					display: none;
				}

				&:before {
					display: inline-block ! important;
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {
	.after-round-section-separator {
		padding-top: 120px;
	}

	.round-section-separator {
		max-width: 800px;
		z-index: 100;
		width: 100%;
		padding: 30px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		.item {
			font-size: 15px;
			h3 {
				display: block;
				font-size: 40px;
				font-weight: 700;
			}
		}

		.col-12:nth-of-type(1) {
			.item {
				margin-left: 15%;
			}
		}

		.col-12:nth-of-type(2) {
			.item {
			}
		}
	}

	.inner-top {
		padding-top: 50px;
	}

	.inner-bottom {
		padding-bottom: 50px;
	}

	.standard-page {
		.inner-top {
			padding-top: 40px;
		}

		.inner-bottom {
			padding-bottom: 40px;
		}
	}

	.round-section-separator {
		.sep {
			border-left: 1px solid #D2D2D2;
		}

		.col-sm-1 {
			max-width: 2px;
		}
	}

	footer {
		.right-border {
			border-right: 1px solid #D2D2D2;
			padding-bottom: 30px;
			margin-right: 20px;
		}
	}

	.arrow-left {
		width: 0;
		height: 0;
		border-top: 15px solid transparent;
		border-bottom: 20px solid transparent;
		position: absolute;
		left: -18px;
		top: 25%;
	}

	section {
		&.image-left {
			.img-bg {
				background-position: right;
			}
		}

		&.image-right {
			.img-bg {
				background-position: right;
			}
		}

		&.image-col {
			overflow: hidden;
			max-width: 100vw;
			.col-md-6 {
				.img-bg {
					width: calc(100vw / 2 - 15px);
					overflow: hidden;
					height: 100%;
					z-index: -100;
					position: absolute;
				}
			}

			.col-md-4 {
				.img-bg {
					width: calc(100vw / 2 - 15px);
					overflow: hidden;
					height: 100%;
					max-width: 100vw;
					z-index: -100;
					position: absolute;
				}
			}
		}
	}

	.box-on-image {
		h1 {
			font-size: 45px;
		}

		h3 {
		}
	}

	.left-and-right-grid {
		padding: 25px 0;
	}

	footer {
		div.col-md-3:nth-child(3n) {
		}
	}

	.standard-text {
		&.image-left {
			.inner {
				padding-left: 50px;
			}
		}

		.inner {
			padding-top: 120px;
			padding-bottom: 50px;
		}
	}

	.left-and-right-grid2 {
		.left {
			text-align: right;
			padding-right: 50px;
			border-right: 1px solid #e9e9e9;
		}

		.right {
			padding-left: 50px;
		}
	}

	#program {
		padding: 50px 0;
		.inner {
			padding-left: 60px;
			padding-bottom: 50px;
		}

		.more {
			display: block;
			margin-left: 30px;
		}
	}

	.program-theme2 {
		.program-time-box {
			width: 160px;
			float: left;
			.btn-time {
				width: 100%;
				display: block;
			}
		}

		.program-head-box {
			float: right;
			width: calc(100% - 200px);
		}
	}

	.heading {
		margin: 0 0 50px 0;
	}

	.price-box-content {
		li {
		}
	}

	.wrapper-contact-box {
		.btn {
			margin-left: auto;
			margin-right: 30px;
			flex: 0 1 auto;
		}
	}

	.wrapper-contact {
		.wrapper-contact-box {
			padding: 10px 40px 10px 80px;
		}
	}
}

.round-section-separator {
	border-radius: 120px;
	h3 {
		display: block;
	}
}

li.yep {
	&:before {
		content: '\f00c';
		font-family: 'FontAwesome';
		margin-right: 15px;
		color: green;
	}
}

li.nope {
	color: #ccc;
	&:before {
		content: '\f00d';
		font-family: 'FontAwesome';
		margin-right: 15px;
		color: red;
	}
}

@media screen and (min-width: 980px) {

.filters {
	.navbar {
		margin-bottom: 35px;
	}
}

	.modal-theme {
		.modal-dialog {
			width: 800px;
			max-width: none;
		}
	}

	#program {
		.inner {
			padding-left: 135px;
		}
	}


	.standard-page {
		.first-home {
	         margin-top: 125px;
			.inner {
				min-height: 400px;
				max-width: 670px;
				margin-left: auto;
				padding-right: 30px;
				padding-top: 80px;
			}
		}
	}

	.col-img-bg-bottom {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: bottom;
	}
}

@media screen and (max-width: 1199px) {
	#navbarTogglerTop {
		.shadow.no-mobile-shadow {
			border: none;
			border-radius: 0;
			box-shadow: unset ! important;
		}
	}
}

@media screen and (min-width: 1200px) {
	.mosaic {
		align-content: center;
		h4 {
			font-size: 25px;
			line-height: 35px;
		}
	}
}

@media screen and (min-width: 1600px) {
	.container-big {
		max-width: 1440px;
	}
}

@media screen and (min-width: 576px) {
	.standard-page {
		.navbar-site > .container {
			flex-wrap: wrap;
			#navbarTogglerTop {
				flex-basis: 100%;
				border-top: 1px solid #f5f5f5;
				margin-top: 10px;
				ul {
					flex-basis: 100%;
					width: 100%;
					justify-content: space-between;
					.nav-link {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

