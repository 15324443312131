%span-checkbox-ico-check {
	display: inline-block;
	position: absolute;
	right: -3px;
	top: -7px;
	font-family: 'FontAwesome';
	content: "\f00c";
	transition: all linear 0.25s;
	border: 0;
	font-size: 14px;
	vertical-align: middle;
	width: auto;
	height: auto;
}
