.navbar-site {
	li {
		margin-top: 0;
		margin-bottom: 0;
	}

	background: white;
	.navbar-nav .nav-link {
		color: black;
		font-weight: 700;
	}

	.show {
		.dropdown-toggle::after {
			transform: rotate(180deg);
		}
	}

	.dropdown-menu {
		padding: $dropdown-padding-y 20px;
       margin-top: 0;
	}

	.dropdown-divider {
		margin: .1rem 0;
	}

	.dropdown-toggle::after {
		display: none;
		content: "";
	}



}
.fixed-top {
  .navbar-site{
   box-shadow: 0 20px 60px rgba(110,113,138,.15)!important;
   padding-bottom:0;
}
}


@media screen and (max-width: 1199px) {
 
}

@media only screen and (min-width: 1200px) {
	.navbar-site {
		.dropdown-toggle::after {
			color: $main-color;
			display: inline-block;
			font-family: 'FontAwesome';
			content: "\f078";
			transition: all linear 0.25s;
			border: 0;
			font-size: 10px;
			vertical-align: middle;
			width: auto;
			height: auto;
		}
	}

	.dropdown-by-hover {
		.dropdown:hover .dropdown-menu {
			display: block;
		}

		.dropdown-submenu {
			position: relative ! important;
		}

		.dropdown-submenu > .dropdown-menu {
			top: 0 ! important;
			left: 100% ! important;
			margin-top: -6px ! important;
			margin-left: -1px ! important;
			border-radius: 0 ! important;
		}

		.dropdown-submenu:hover > .dropdown-menu {
			display: block ! important;
		}
	}
}

