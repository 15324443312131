/* Slider */

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;
	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track , .slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	transition-duration: 0.2s;
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
	&:before , &:after {
		content: "";
		display: table;
	}

	&:after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}

	img {
		display: block;
	}

	&.slick-loading img {
		display: none;
	}

	display: none;
	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

.speaker-slider {
	padding: 0 10px;
	img {
		margin-bottom: 10px;
	}

	.cicle {
		display: inline;
		width: 120px;
		height: 120px;
		border-radius: 120px;
		max-width: 100%;
		max-height: 100%;
		margin-bottom: 40px;
	}
}

#speaker-slider {
	.slick-list.draggable {
		max-height: 360px;
	}

	h4 {
		font-size: 20px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	p {
		color: #888888;
		margin-bottom: 20px;
	}
}

#testimonial-slider {
	.slick-list.draggable {
	}

	.slick-prev , .slick-next {
		top: 150px;
	}

	.slick-current {
		.testimonial-slider {
		}
	}

	.testimonial-slider {
		border: 1px solid #ECECEC;
		background: white;
		z-index: 50;
		overflow: visible;
		transition: 0.6s all;
		background-image: url('../images/ico_test.png');
		background-size: auto auto;
		background-position: center;
		background-repeat: no-repeat;
		h5 {
			font-size: 15px;
			font-weight: 800;
		}

		p {
			font-size: 15px;
			color: #787878;
			font-style: italic;
			margin-bottom: .5rem;
			max-width: 80%;
			margin-left: auto;
			margin-right: auto;
		}

		span {
			font-style: italic;
			font-size: 12px;
		}

		padding: 40px 0;
		margin: 40px 0px;
	}
}

#speaker-slider {
	.slick-dots {
		bottom: unset;
	}
}

@media screen and (max-width: 980px) {
	.col-12-slick {
		padding-left: 30px ! important;
		padding-right: 30px ! important;
	}
}

@media screen and (min-width: 980px) {
	#testimonial-slider-box {
		min-height: 400px;
	}

	#testimonial-slider {
		.testimonial-slider-item {
			padding-top: 50px;
			padding-bottom: 50px;
			z-index: -999;
		}

		.slick-slide {
			z-index: -999;
			&.slick-current {
				overflow: visible;
				z-index: 100;
				transform: scale3d(1.4, 1.4, 1);
				transition-duration: 0.2s;
				perspective: 20px;
				.testimonial-slider {
					opacity: 1;
					overflow: visible;
					margin: 10px 0;
					z-index: 999;
					background: white;
				}
			}
		}
	}
}

