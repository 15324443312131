.pagination {
	display: flex;
	@include  list-unstyled();
	@include  border-radius();
	justify-content: flex-end;
}

.page-link {
	position: relative;
	display: block;
	padding: $pagination-padding-y $pagination-padding-x;
  margin-left: -$pagination-border-width;
	line-height: $pagination-line-height;
	color: $pagination-color;
	background-color: $pagination-bg;
	&:hover {
		z-index: 2;
		color: $pagination-hover-color;
		text-decoration: none;
	}

	&:focus {
		z-index: 2;
		outline: $pagination-focus-outline;
		box-shadow: $pagination-focus-box-shadow;
	}

	&:not(:disabled):not(.disabled) {
		cursor: pointer;
	}
}

.page-item {
	margin-left: 2px;
	&:first-child {
		.page-link {
			margin-left: 0;
		}
	}

	&:last-child {
		.page-link {
		}
	}

	&.active .page-link {
		z-index: 1;
		color: $pagination-active-color;
		border-radius: 20px;
	}

	&.disabled .page-link {
		color: $pagination-disabled-color;
		pointer-events: none;
		cursor: auto;
		background-color: $pagination-disabled-bg;
		border-color: $pagination-disabled-border-color;
	}
}

.pagination-lg {
	@include  pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg , $line-height-lg , $border-radius-lg);
}

.pagination-sm {
	@include  pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm , $line-height-sm , $border-radius-sm);
}

